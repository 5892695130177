import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"

export default () => {
  useEffect(() => {
    navigate("/giotrif/medicine-availment/")
  })

  return <Layout seoTitle="Medicine Availment"></Layout>
}
